import { ApiError } from '~/errors/api-error';

export const httpMethods = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'] as const;
export const supportedContentTypes = ['application/json', 'application/pdf', 'text/csv'] as const;
export const FILE_SIZE_LIMIT = 200 * 1024 * 1024; // 200MB

export type HttpMethods = (typeof httpMethods)[number];
export type SupportedContentTypes = (typeof supportedContentTypes)[number];

/* -------------------------------------------------------------------------------------------------
 * Helpers
 * -----------------------------------------------------------------------------------------------*/

function toUrl(path: string, queryParams?: URLSearchParams) {
  const prefix = path.startsWith('https://');
  const url = prefix ? new URL(path) : new URL(`${API_PATH}${path}`, BACKEND_HOST);
  if (queryParams) {
    url.search = queryParams.toString();
  }
  return url.toString();
}

function toFormData<T>(data: T) {
  if (data instanceof Object) {
    return Object.entries(data).reduce((formData, [key, value]) => {
      formData.append(key, value);
      return formData;
    }, new FormData());
  }
}

function toRequestBody<T>(data?: T, isMultiPart?: boolean) {
  if (!data) {
    return undefined;
  }

  return isMultiPart ? toFormData(data) : JSON.stringify(data);
}

/* -------------------------------------------------------------------------------------------------
 * Fetch
 * -----------------------------------------------------------------------------------------------*/
interface RequestOptions extends Omit<RequestInit, 'method' | 'body'> {
  headers?: HeadersInit;
}

export interface FetchConfig<RequestBody> {
  method?: HttpMethods;
  queryParams?: URLSearchParams;
  body?: RequestBody;
  options?: RequestOptions;
  isMultiPart?: boolean;
}

export async function fetchApi<RequestBody, ResponseType>(
  endpoint: string,
  config: FetchConfig<RequestBody> = { method: 'GET' },
) {
  const { body, method, queryParams, isMultiPart, options } = config;

  const url = toUrl(endpoint, queryParams);
  const { file } = (body || {}) as Record<string, File>;

  if (isMultiPart && file instanceof File && file?.size >= FILE_SIZE_LIMIT) {
    throw new ApiError('FILE_EXCEEDS_SIZE_LIMIT', 413);
  }

  const response = await fetch(url, {
    ...options,
    method: method,
    body: toRequestBody(body, isMultiPart),
    headers: {
      ...(!isMultiPart ? { 'Content-Type': 'application/json' } : {}),
      ...options?.headers,
    },
  });

  if (!response.ok) {
    const requestId = response.headers.get('X-Request-Id') ?? undefined;

    const errorResponse = await response.json().catch(() => null);
    const errorDetail = errorResponse?.detail || errorResponse;
    const errorMessage = (typeof errorDetail === 'string' && errorDetail) || response.statusText;

    throw new ApiError(errorMessage, response.status, errorDetail, requestId);
  }

  const contentType = response.headers.get('Content-Type')?.split(';')?.[0] as SupportedContentTypes | null;

  if (!contentType) {
    return undefined as ResponseType;
  }

  if (contentType === 'application/json') {
    return response.json() as Promise<ResponseType>;
  }

  if (contentType === 'text/csv' || contentType === 'application/pdf') {
    return response.blob() as Promise<ResponseType>;
  }

  throw new ApiError(`Unsupported Content-Type ${contentType}`, response.status);
}
