export class ApiError extends Error {
  constructor(
    message: string,
    public status: number,
    public detail?: unknown,
    public requestId?: string,
  ) {
    super(message);

    this.name = 'APIError';
    this.status = status;
    this.detail = detail;
    this.requestId = requestId;

    Object.setPrototypeOf(this, ApiError.prototype);
  }
}
